import { Box } from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useState, useEffect, useMemo } from "react";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useQuery } from "@tanstack/react-query";
import { getLocations } from "../../../services/propertiesServices";
import { useError } from "../../../hooks/handleError";
import { getStateCode, handleError } from "../../../utils/functions";
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { Paper } from "@mui/material";
import BaseAlert from "../../BaseComponents/BaseAlert";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
    GET_LAST_INPUT_SEARCH,
    GET_LAST_SEARCH,
    GET_LOCATION,
    GET_PROFILE_FINISHED,
    GET_STORE_COMPLETE
} from "../../../constants/store/getters";
import { useStore } from "../../../hooks/store/store";
import { useHistory } from "react-router-dom";
import { HOME_ROUTE } from "../../../constants/routes";
import { GET_NEW_VALUES_FOR_SEARCH, PRIMARY_COLOR } from "../../../constants/constants";
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import { useTranslation } from 'react-i18next';
import {
    CURRENT_LOCATION_LABEL,
    LIVE_SEARCH_LABEL
} from "../../../i18n/i18nLabel";

export default function SearchLocation() {

    const { t } = useTranslation();

    const history = useHistory();

    const { state, dispatch } = useStore();

    const theme = useTheme();

    const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    const isMdOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

    const { error, setErrorMessage, clearError } = useError();

    const [searchValue, setSearchValue] = useState(null);

    const [searchLocationAction, setSearchLocationAction] = useState(false);

    const [inputSearchValue, setInputSearchValue] = useState("");

    const handleOptions = () => {
        //
    };

    const { data: locations } = useQuery({
        queryKey: ["locations", inputSearchValue],
        queryFn: async () => {
            try {
                setSearchLocationAction(false);
                return await getLocations(inputSearchValue)
            } catch (e) {
                setErrorMessage(handleError(e));
            }
        },
        enabled: searchLocationAction,
    });

    useEffect(() => {
        if (inputSearchValue.length >= 3) {
            setSearchLocationAction(true);
        } else {
            setSearchLocationAction(false);
        }
    }, [inputSearchValue]);

    const getOptions = () => {
        const currentLocationOption = state[GET_LOCATION] && state[GET_LOCATION]?.city && state[GET_LOCATION]?.regionName
            ? { description: t(CURRENT_LOCATION_LABEL), isCurrentLocation: true }
            : null;
        const lastInputSearchOption = state[GET_LAST_INPUT_SEARCH]?.searchFor
            ? { ...state[GET_LAST_INPUT_SEARCH], isLastSearch: true }
            : null;

        const searchedLocations = locations ? locations.filter((item) => item?.searchFor !== lastInputSearchOption?.searchFor) : [];

        return [
            currentLocationOption,
            lastInputSearchOption,
            ...searchedLocations
        ].filter(option => option !== null);
    };

    const searchBar = useMemo(() => {
        return (
            <Box>
                <BaseAlert alert={error} onClose={() => { clearError(); }} />
                <Autocomplete
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderColor: 'transparent',
                            borderRadius: '20px',
                            height: "50px",
                            background: "white",
                            width: isSmOrSmaller ? "250px" : isMdOrSmaller ? "350px" : "500px",
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                border: "solid 3px",
                                borderColor: PRIMARY_COLOR,
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: "solid 3px",
                                borderColor: PRIMARY_COLOR
                            },
                        },
                        '& .MuiAutocomplete-option:hover': {
                            backgroundColor: 'transparent',
                        },
                        '& .MuiAutocomplete-option.Mui-focused': {
                            backgroundColor: 'transparent',
                        },
                    }}
                    ListboxProps={{
                        style: {
                            maxHeight: '150px',
                            overflow: 'auto',
                        }
                    }}
                    PaperComponent={(props) => (
                        <Paper {...props} style={{ marginTop: '8px' }} />
                    )}
                    isOptionEqualToValue={() => handleOptions}
                    size="small"
                    filterOptions={(x) => x}
                    noOptionsText="No Locations"
                    options={getOptions()}
                    autoHighlight
                    includeInputInList
                    getOptionLabel={(option) => {
                        return (
                            option !== null
                            && option !== undefined
                            && option !== "")
                            ? option["description"]
                            : ""
                    }
                    }
                    inputValue={inputSearchValue}
                    onInputChange={(event, newInputValue) => {
                        if (newInputValue !== t(CURRENT_LOCATION_LABEL)) {
                            setInputSearchValue(newInputValue);
                        }
                    }}
                    value={searchValue}
                    onChange={async (event, newValue) => {

                        let lastSearch = localStorage.getItem("last_search")
                            ? JSON.parse(localStorage.getItem("last_search"))
                            : GET_NEW_VALUES_FOR_SEARCH(null);

                        if (newValue?.isCurrentLocation) {
                            const location = {
                                searchBy: "City",
                                description: state[GET_LOCATION]?.city + ", " + getStateCode(state[GET_LOCATION]?.regionName),
                                searchFor: state[GET_LOCATION]?.city,
                                state: getStateCode(state[GET_LOCATION]?.regionName)
                            }
                            localStorage.setItem("last_input_search", JSON.stringify(location));
                            dispatch({ action: GET_LAST_INPUT_SEARCH, value: location });
                            if (lastSearch) {
                                lastSearch.search = state[GET_LOCATION]?.city;
                                lastSearch.searchBy = "City";
                                lastSearch.searchState = getStateCode(state[GET_LOCATION]?.regionName);
                                localStorage.setItem("last_search", JSON.stringify(lastSearch));
                                dispatch({ action: GET_LAST_SEARCH, value: lastSearch });
                            }
                            setSearchValue(location);
                        } else {
                            localStorage.setItem("last_input_search", JSON.stringify(newValue));
                            dispatch({ action: GET_LAST_INPUT_SEARCH, value: newValue });
                            if (lastSearch) {
                                lastSearch.search = newValue?.searchFor;
                                lastSearch.searchBy = newValue?.searchBy;
                                lastSearch.searchState = newValue?.state;
                                localStorage.setItem("last_search", JSON.stringify(lastSearch));
                                dispatch({ action: GET_LAST_SEARCH, value: lastSearch });
                            }
                            setSearchValue(newValue);
                        }
                        localStorage.removeItem("scroll");
                        dispatch({ action: GET_STORE_COMPLETE, value: true });
                        localStorage.setItem("page", HOME_ROUTE);
                        localStorage.setItem("profile_finished", true);
                        dispatch({ action: GET_PROFILE_FINISHED, value: true });
                        history.push(HOME_ROUTE);
                    }}
                    renderInput={(params) => (
                        <TextField {...params}
                            autoComplete="off"
                            value={searchValue}
                            placeholder={t(LIVE_SEARCH_LABEL)}
                            InputProps={{
                                ...params.InputProps,
                                autoComplete: 'off',
                                startAdornment: <InputAdornment
                                    position="end">
                                    <SearchIcon
                                        fontSize='medium'
                                    />
                                </InputAdornment>,
                            }}
                        />
                    )}
                    renderOption={(prop, option) => (
                        <Box
                            {...prop}
                            sx={{ padding: "6px", minHeight: "40px" }}
                        >
                            {option?.isCurrentLocation
                                ? <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <FmdGoodOutlinedIcon fontSize="small" />
                                    &nbsp;
                                    {option.description}

                                </Box>
                                : option?.isLastSearch
                                    ? <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <ScheduleOutlinedIcon fontSize="small" />
                                        &nbsp;
                                        {option.description}
                                    </Box>
                                    : option.description
                            }
                        </Box>
                    )}
                />
            </Box>
        );
    }, [
        state[GET_STORE_COMPLETE],
        state[GET_LOCATION],
        inputSearchValue,
        searchValue,
        locations,
        isSmOrSmaller,
        isMdOrSmaller
    ]);

    return searchBar;
}