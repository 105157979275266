import { Box } from "@mui/material";
import Background from "../Images/Background";
import Background2 from "../Images/Background2";
import Background4 from "../Images/Background4";
import { useMemo } from "react";

export default function LandingMainSection() {

    const randomBackground = () => {

        const backs = [<Background />, <Background2 />, <Background4 />];

        const i = Math.floor(Math.random() * 3);

        return (
            backs[i]
        );
    }

    const back = useMemo(() => {
        return (<Box
            sx={{
                width: '100vw',
                position: 'fixed',
                top: -54,
                left: 0,
                zIndex: -1,
                height: "70vh",
            }}
        >{
                randomBackground()
            }
        </Box>)
    }, [])

    return back;
}